import React from "react";
import Helmet from "react-helmet";
import config from "utils/siteConfig";
import { useLocation } from "@reach/router";
import { AdministrativeArea, GeoShape, Place, Text, WebPage } from "schema-dts";
import { embeddedWebSite } from "components/common/meta/StructuredWebSite";
import { addContextToSchema, StructuredDataRenderer } from "components/common/meta/utils";
import T from 'types/index'

const StructuredWebPage = ({
  description,
  title,
  sexoWebsiteDescription = false,
  showAggregateRating = true,
  areaServed = "France"
}: {
  description: string;
  title: string;
  sexoWebsiteDescription?: boolean
  showAggregateRating?: boolean;
  areaServed?: AdministrativeArea | GeoShape | Place | Text
}) => {
  const pathname = useLocation().pathname;
  const pageUrl = `${config.siteUrl}${pathname}`;
  const webPage: WebPage = {
    "@type": "WebPage",
    url: pageUrl,
    inLanguage: "fr-FR",
    description: description,
    name: title,
    isPartOf: embeddedWebSite({
      showAggregateRating,
      sexoWebsiteDescription
    }),
    about: {
      "@type": "Service",
      serviceType: sexoWebsiteDescription ? "Consultation de sexologue" : "Consultation en ligne",
      areaServed: areaServed,
      url: pageUrl,
    },
  };
  const schema = addContextToSchema(webPage);

  return <StructuredDataRenderer schema={schema} />;
};

export const cityAsAreaServed = (city: T.Charles.City): Place => ({
  "@type": "City",
  name: city.name,
  sameAs: city.external_url
})

export default StructuredWebPage;
