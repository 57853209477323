import React from "react";
import Helmet from "react-helmet";
import config from "utils/siteConfig";
import { Organization, WithContext } from "schema-dts";
import { addContextToSchema, StructuredDataRenderer } from "components/common/meta/utils";

type Options = {
  showAggregateRating?: boolean;
  showMainEntityOfPage?: boolean;
};
const defaultOptions: Options = {
  showAggregateRating: true,
  showMainEntityOfPage: true,
};
export const embeddedOrganization = (opts: Options): Organization => {
  const options: Options = Object.assign({}, defaultOptions, opts);
  return {
    "@type": "Organization",
    name: config.siteName,
    description: config.organizationDescription,
    slogan: config.organizationSlogan,
    legalName: config.legalName,
    url: config.siteUrl,
    logo: {
      "@type": "ImageObject",
      url: `${config.siteUrl}${config.logo}`,
    },
    sameAs: [
      `https://www.facebook.com/${config.facebook}`,
      `https://www.twitter.com/${config.twitter}`,
      config.linkedInUrl,
      config.youtubeChannelUrl,
      config.infogreffeUrl,
      config.googleSearchUrl,
      config.tiktokUrl,
      config.instragramUrl,
    ],
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: config.phoneNumber,
        email: "contact@charles.co",
        contactType: "customer support",
        areaServed: "FR",
        availableLanguage: "French",
      },
    ],
    address: {
      "@type": "PostalAddress",
      postalCode: config.legalPostcode,
      addressLocality: config.legalCity,
      streetAddress: config.legalAddress,
      addressCountry: {
        "@type": "Country",
        name: "France",
      },
    },
    ...(options.showAggregateRating && {
      aggregateRating: {
        "@type": "AggregateRating",
        bestRating: config.bestRating,
        worstRating: config.worstRating,
        ratingValue: config.ratingValue,
        ratingCount: config.ratingCount,
        itemReviewed: {
          "@type": "Organization",
          name: config.siteName,
        },
      },
    }),
    ...(options.showMainEntityOfPage && {
      mainEntityOfPage: {
        "@type": "WebSite",
        url: config.siteUrl,
        name: config.siteName,
        genre: "Health",
        description: config.organizationDescription,
        about: {
          "@type": "MedicalBusiness",
          url: config.siteUrl,
          name: config.siteName,
          currenciesAccepted: "EUR",
          openingHours: "Mo,Tu,We,Th,Fr,Sa,Su 08:00-22:00",
          paymentAccepted: "Credit Card, Paypal",
          priceRange: "$",
          image: `${config.siteUrl}${config.logo}`,
          telephone: config.phoneNumber,
          address: {
            "@type": "PostalAddress",
            name: config.fullAddress,
          },
        },
      }
    }),
  };
};
const StructuredOrganization = () => {
  const organization: Organization = embeddedOrganization({});
  const schema: WithContext<Organization> = addContextToSchema(organization);

  return <StructuredDataRenderer schema={schema} />
};

export default StructuredOrganization;
