export const CITIES_STATUS = {
  active: "active",
  ignore: "ignore",
  redirect: "redirect"
}

export const PATHOLOGIES_STATUS = {
  active: "active",
  ignore: "ignore",
  redirect: "redirect"
}

export const ENVIRONMENT_STATUS = {
  prod: "prod",
  beta: "beta"
}

export const airtableEnvironmentValues = () => {
  if(process.env.AIRTABLE_ENVIRONMENT === "staging"){
    return ["staging", "production"]
  } else {
    return ["production"]
  }
}