import React, { useState } from "react";
import { annuarySlug, citySlug } from "../utils/slugs";
import { graphql } from "gatsby";
import config from 'utils/siteConfig';
import Arrow from "images/icons/arrow-right.svg";
import CharlesMeta from "components/common/meta/CharlesMeta";
import Segment from 'utils/segment'
import { Container } from "react-bootstrap";
import FooterDepartments from "components/common/FooterDepartments";
import { get, isEmpty } from 'utils/lodashExtracts';
import Image from "components/common/utils/Image";
import { Layout } from "components/common";
import { Link } from "gatsby";
import Section from "components/common/Section";
import StructuredWebPage from "components/common/meta/StructuredWebPage";
import { CITIES_STATUS } from 'utils/airtable/data';
import T from 'types/index'
import Breadcrumb, { annuaryDepartmentBreadcrumbs } from "templates/Blog/common/Breadcrumb";

type Props = {
  data: {
    department: T.Charles.DepartmentRecord
  }
}

const IndexPage = (props: Props) => {
  const department: T.Charles.DepartmentRecord = props.data.department
  const pageTitle = `Sexologues ${department.data.A_LIEU}`
  const metaTitle = `Sexologues ${department.data.A_LIEU} | Trouvez un médecin près de chez vous`
  const metaDesc = `Annuaire des sexologues exerçant ${department.data.A_LIEU}. Prenez RDV en quelques clics. Vous pouvez aussi consulter en ligne un sexologue en moins de 24h.`
  const activeCities = department.data.cities.filter((city) => city.data.status == CITIES_STATUS.active)
  const markdownSpinner = get(department, 'data.spinner.childMarkdownRemark.html')
  return (
    <Layout
      showM6Banner
      showLinksInFooter={false}
    >
      <CharlesMeta
        data={{
          title: metaTitle,
          description: metaDesc,
        }}
        segmentCategory={Segment.CATEGORIES.ANNUARY}
      />
      <StructuredWebPage
        title={pageTitle}
        description={metaDesc}
        areaServed={department.data.name}
        sexoWebsiteDescription
      />
      <div className="text-left landing-page">
        <Section className="annuary-city-section">
          <Container className="annuary-city-head-div">
            <Image
              filename="sex-annuary/Icon-departement.svg"
              title="illustration d'un département"
              alt="illustration-d-un-département"
              className="department-img"
            />

            <h1>{pageTitle}</h1>
            <span className="city-not-found-disclaimer">
              Si votre ville ne se trouve dans la liste ci-dessous, c'est que
              nous n'y avons pas trouvé de sexologues
            </span>
          </Container>
        </Section>
        <Section className="landing-section-beige py-5">
          <Container>
            <div className="d-flex flex-column align-items-center">
              {activeCities.map((city) => (
                <h2 className="footer-city-wrapper">
                  <Link to={citySlug(department.data.slug, city.data.slug)}>
                    Sexologues {city.data.A_LIEU}
                  </Link>
                </h2>
              ))}
            </div>
          </Container>
        </Section>
        <Section className="annuary-city-section annuary-card-consult ">
          <div className="annuary-search-consult-div mb-4">
            <h3>
              Vous souhaitez consulter un sexologue facilement ?
              <br />
              Consultez dès maintenant sur charles.co
            </h3>
            <span>
              Consultation (téléphone, vidéo ou messagerie privée) depuis chez
              vous avec un médecin sexologue français ({config.consultationPrice}€). Délivrance d’une
              ordonnance pour des traitements si nécessaire.
            </span>
            <Link
              to="/consulter/"
              className="text-white btn-black consult-div-cta d-flex justify-content-center align-items-center"
            >
              Consulter
            </Link>
          </div>
          <Section className="annuary-city-section">
            <Container className="annuary-city-description">
              <div className="my-3">
                <Breadcrumb links={annuaryDepartmentBreadcrumbs(department.data)} />
              </div>
              <div dangerouslySetInnerHTML={{ __html: markdownSpinner }} />
            </Container>
          </Section>
        </Section>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query($id: String!) {
    department: airtableDepartments(id: { eq: $id } ) {
      id
      data {
        name
        code
        slug
        region
        A_LIEU
        cities {
          data {
            name
            slug
            status
            A_LIEU
          }
        }
        spinner {
          ...AirtableMarkdownFields
        }
      }
    }
  }
`;
