import React from 'react';
import cx from 'classnames';

import './styles.sass';

const Section = (props) => (
  <section {...props} className={cx('landing-section', props.className)}>
    {props.children}
  </section>
);

export default Section;
