import React from "react";
import Helmet from "react-helmet";
import { Thing, WithContext } from "schema-dts";

export const addContextToSchema = <T extends Thing>(
  schema: T
): WithContext<T> => {
  // @ts-ignore
  return { "@context": "https://schema.org", ...schema };
};

export const StructuredDataRenderer = <T extends Thing>({
  schema,
}: {
  schema: WithContext<T>;
}) => (
  <Helmet defer={false}>
    <script type="application/ld+json">{JSON.stringify(schema)}</script>
  </Helmet>
);
