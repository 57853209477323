import config from "utils/siteConfig";
import { WebSite } from "schema-dts";
import { embeddedOrganization } from "components/common/meta/StructuredOrganization";

export const embeddedWebSite = ({
  showAggregateRating = true,
  sexoWebsiteDescription = false
}: {
  showAggregateRating: boolean;
  sexoWebsiteDescription: boolean;
}): WebSite => ({
  "@type": "WebSite",
  url: config.siteUrl,
  name: config.siteName,
  genre: "Health",
  description: sexoWebsiteDescription ? config.websiteSexoDescriptionMeta : config.websiteGenericDescription,
  publisher: embeddedOrganization({
    showMainEntityOfPage: false,
    showAggregateRating,
  }),
});
